<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("teachersList.title") }}
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <b-form>
              <div class="row">
                <div class="col-4" v-if="showMainDepartment()">
                  <b-form-group>
                    <template v-slot:label>
                      Район
                    </template>
                    <b-form-select
                        v-model="filter.regionId"
                        :options="regions"
                        @change="reloadSchools"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      Школа
                    </template>
                    <b-form-select
                        v-model="filter.schoolId"
                        :options="schools"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      Инн
                    </template>
                    <b-form-input
                        v-model="filter.inn"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      Фамилия
                    </template>
                    <b-form-input
                        v-model="filter.firstName"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      Имя
                    </template>
                    <b-form-input
                        v-model="filter.name"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <b-button type="button" @click="reloadData" variant="primary"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }">{{
                  $t("common.filter")
                }}
              </b-button>
            </b-form>
            
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th>#</th>
                <th class="text-left">
                  {{ $t("teachersList.school") }}
                </th> 
                <th class="text-left">
                  {{ $t("teachersList.inn") }}
                </th>
                <th class="text-left">
                  {{ $t("teachersList.firstName") }}
                </th>
                <th class="text-left">
                  {{ $t("teachersList.name") }}
                </th>
                <th class="text-left">
                  {{ $t("teachersList.parentName") }}
                </th>
                <th class="text-left">
                  {{ $t("teachersList.contactPhone") }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, k) in teachers" :key="item.id">
                <td>{{ k + 1 }}</td>
                <td>{{ item.schoolName }}</td>
                <td>{{ item.inn }}</td>
                <td>{{ item.firstName }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.parentName }}</td>
                <td>{{ item.contactPhone }}</td>        
              </tr>
              </tbody>
            </table>
            <Pagination v-bind:loading="loading" v-bind:total="pageModel.Total" v-bind:current="pageModel.Showed" v-bind:perpage="filter.perPage"
                        v-on:open-page="openPage" v-on:per-page-change="perPageChange" ref="cartPager">

            </Pagination>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import Pagination from "@/views/pages/edu/common/Pagination.vue";
import {mapGetters} from "vuex";

export default {
  name: "EducationDepartmentTeachers",
  components: {
    Pagination,
    KTPortlet
  },
  data() {
    return {
      loading: false,
      teachers: null,
      schools:[],
      regions:[],
      pageModel:{
        Total: 1000,
        Showed: 10
      },
      filter:{
        schoolId: 0,
        regionId: 0,
        page:1,
        perPage: 10        
      },
    };
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser"
    ])
  },
  mounted() {
    ApiService.querySecured("dictionaries/regions", {}).then(({data}) => {
      this.regions = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });
    
    ApiService.querySecured("dictionaries/schools", {}).then(({data}) => {
      this.schools = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });
    
    this.reloadData();
  },
  methods: {
    openPage(page){
      this.filter.page = page;

      this.reloadData();
    },
    perPageChange(perPage){
      this.filter.perPage = perPage;

      this.reloadData();
    },
    reloadData() {
      let $this = this;
      $this.loading = true;
      
      ApiService.postSecured("educationDepartment/teachers", this.filter).then(({data}) => {
        $this.teachers = data.list;
        $this.pageModel.Showed = data.showed;
        $this.pageModel.Total = data.total;
      }).catch(({response}) => {
        console.log(response);
      }).finally(function (){
        $this.loading = false;
      });
    },
    reloadSchools(){
      ApiService.querySecured("dictionaries/schools",
          {params: {regionId: this.filter.regionId}})
          .then(({data}) => {
            data.dictionary.unshift({ value: 0, text: '' });
            this.schools = data.dictionary;
          }).catch(({response}) => {
        console.log(response);
      });
    },
    showMainDepartment(){
      return this.currentUser.role == 'ROLE_MAIN_EDUCATION_DEPARTMENT';
    }
  }
};
</script>
